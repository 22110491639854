function Container(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 2),   // 16: 0-599
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3)  // 24: 600-1199
          },
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 5)  // 40: 1200-1599
          },
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 10) // 80: 1600->
          },
        }
      }
    }
  }
}

export default Container