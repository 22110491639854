import merge from 'lodash/merge'

import Button from './Button'
import InputLabel from './InputLabel'
import OutlinedInput from './OutlinedInput'
import Container from './Container'
import Tab from './Tab'
import Chip from './Chip'

function components(theme) {
  return merge(
    Button(theme),
    InputLabel(theme),
    OutlinedInput(theme),
    Container(theme),
    Tab(theme),
    Chip(theme)
  )
}

export default components