function InputLabel(theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle2,
          textAlign: 'left'
        },
        asterisk: {
          color: theme.palette.secondary.main
        }
      }
    }
  }
}

export default InputLabel