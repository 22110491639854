function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          ...theme.typography.body1,
          color: theme.palette.grey[400],
          height: 32,
          margin: 0,
          padding: theme.spacing(0.5, 1),
          backgroundColor: 'transparent',
          borderRadius: '0px',
          border: `1px solid ${theme.palette.grey[300]}`
        },
        label: {
          padding: 0
        }
      }
    }
  }
}

export default Chip