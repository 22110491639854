export const PAGES = {
  DEFAULT: '/',
  TEAM: '/team/',
  ROBERT: '/team/robert/',
  BLOG: '/blog/',
  CONTACT: '/contact/',
  404: '/404'
}

export const MAIN_MENU = [
  {
    label: 'Team',
    url: PAGES.TEAM
  },
  {
    label: 'Blog',
    url: PAGES.BLOG
  },
  {
    label: 'Contact',
    url: PAGES.CONTACT
  }
]