import React from 'react'
import { Container, Typography, Box } from '@mui/material'

import Logo from './Logo'
import GenericLink from './GenericLink'
import { MAIN_MENU, LogoIcon } from '../../constants'

const Footer = () => {
  return (
    <Box
      sx={theme => ({
        py: 10,
        backgroundColor: 'primary.main',
        [theme.breakpoints.down('sm')]: {
          pt: 5,
          pb: 15
        }
      })}
    >
      <Container
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'unset',
            gap: 5
          }
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            gap: 3,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              gap: 1.5
            }
          })}
        >
          <Logo Icon={LogoIcon} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography variant='body2' sx={{ color: 'common.white' }}>HUMATIC LABS LLC</Typography>
            <Typography variant='subtitle2' sx={{ color: 'grey.300' }}>All rights reserved</Typography>
          </Box>
        </Box>
        <Box sx={theme => ({
          display: 'flex',
          gap: 15,
          [theme.breakpoints.down('md')]: {
            gap: 5,
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 5,
          },
        })}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {MAIN_MENU.map((item) => (
              <GenericLink key={item?.url} color='secondary' {...item} />
            ))}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <GenericLink openNewTab color='secondary' label='Linkedin' url='https://linkedin.com/in/robert-taylor-engineer/' icon />
            <GenericLink openNewTab color='secondary' label='Toptal' url='https://www.toptal.com/resume/robert-taylor' icon />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
