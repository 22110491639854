import { alpha } from '@mui/material/styles'

// M-UI v5.2.7 has 25 default elevation levels stored on an array
// add here custom boxShadows; the first one has the (index) number 25
const shadows = (theme) => [
    `0px 24px 24px ${alpha(theme.palette.common.black, 0.08)}`, // boxShadow: 4 - Units column border
    `0px 0px 4px  ${alpha(theme.palette.common.black, 0.25)}`, // boxShadow: 3 - border
    `0px 4px 8px ${alpha(theme.palette.common.black, 0.16)}`, // boxShadow: 2 - pin
    `0px 4px 24px ${alpha(theme.palette.common.black, 0.08)}`, // boxShadow: 1 - Unit details panel
]

export default shadows
