import React, { useEffect, useContext } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { Box, Typography } from '@mui/material'

import { NotificationContext } from '../../contexts'

const Notification = () => {
  const { duration, state, show, closeNotification } = useContext(NotificationContext)
  const { error, message, secondaryMessage } = state
  const Icon = error ? ErrorIcon : CheckCircleIcon

  useEffect(() => {
    if (show) {
      // Hide notification after **duration** time passes
      const timer =  setTimeout(() => {
        closeNotification()
        clearTimeout(timer)
      }, duration)
    }
  }, [show, closeNotification, duration])

  return (
    <Box
      onClick={closeNotification}
      sx={theme => ({
        py: 1.5,
        px: 2,
        width: 'calc(100% - 32px)',
        position: 'fixed',
        top: show ? 80 : -80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'top .7s ease-in-out',
        backgroundColor: error ? theme.palette.error.main : theme.palette.success.main,
        zIndex: theme.zIndex.appBar - 1
      })}
    >
      <Icon fontSize='medium' sx={{color: 'common.white', mr: 1}}/>
      <Typography variant='subtitle2' sx={{ color: 'common.white' }}>
        {`${message} `}<span>{secondaryMessage}</span>
      </Typography>
    </Box>
  )
}

export default Notification