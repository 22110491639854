import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, Box } from '@mui/material'

import ButtonLink from '../ButtonLink'
import GenericLink from '../GenericLink'
import { MAIN_MENU } from '../../../constants'

const MobileDropdownMenu = ({ open, onClose }) => {
  const pageLinks = MAIN_MENU.slice(0, 2)
  const [ contact ] = MAIN_MENU.slice(-1)

  return (
    <Drawer
      anchor='top'
      variant='temporary'
      transitionDuration={300}
      sx={theme => ({
        zIndex: (theme.zIndex.appBar - 1),  // so that Drawer displays under the AppBar
      })}
      open={open}
      onClose={onClose}
      elevation={0}
      ModalProps={{
        slotProps: {
          backdrop: {
            sx: { backgroundColor: 'rgba(20, 23, 31, 0.9)' }  // deep cove @ 90%
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '24px',
          height: '100%',
          px: 2,
          py: 3,
          mt: 10,
          backgroundColor: 'primary.main',
        }}
      >
        {pageLinks.map((item) => (
          <Box key={item?.url} sx={{ width: '100%', '& > a': { justifyContent: 'center' }}}>
            <GenericLink {...item} color='secondary' onClick={onClose} />
          </Box>
        ))}
        <ButtonLink color='primary' {...contact} onClick={onClose} fullWidth={true} />
      </Box>
    </Drawer>
  )
}

MobileDropdownMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MobileDropdownMenu
