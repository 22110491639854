function Button(theme) {
    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    height: 48,
                    padding: theme.spacing(1.5, 2),
                    ...theme.typography.body2,
                    textAlign: 'center',
                    textTransform: 'none',
                    borderRadius: 0
                },
                contained: {
                    ...theme.typography.body2,
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.secondary.main,
                    border: `1px solid ${theme.palette.secondary.main}`,
                    '&:hover': {
                        backgroundColor: theme.palette.common.white,
                        border: `1px solid ${theme.palette.secondary.main}`,
                        color: theme.palette.secondary.main
                    },
                    '&:disabled': {
                        color: `${theme.palette.common.white} !important`,
                        backgroundColor: `${theme.palette.secondary.light} !important`,
                        cursor: 'default'
                    }
                },
                containedSecondary: {
                    ...theme.typography.body2,
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.text.primary,
                    border: `1px solid ${theme.palette.common.white}`,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.common.white}`,
                        color: theme.palette.common.white
                    },
                    '&:disabled': {
                        color: `${theme.palette.grey[400]} !important`,
                        backgroundColor: `${theme.palette.grey[300]} !important`,
                        cursor: 'default'
                    }
                },
                outlined: {
                    ...theme.typography.subtitle1,
                    backgroundColor: theme.palette.common.white,
                    border: `1px solid ${theme.palette.primary.main}`,
                    py: 1,
                    px: 2
                }
            }
        },
    }
}

export default Button
