import React from 'react'
import { Box } from '@mui/material'

import Logo from '../Logo'
import ButtonLink from '../ButtonLink'
import GenericLink from '../GenericLink'
import { MAIN_MENU } from '../../../constants'

const DesktopHeader = () => {
  const pageLinks = MAIN_MENU.slice(0, 2)
  const [ contact ] = MAIN_MENU.slice(-1)

  return (
    <Box sx={theme => ({
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    })}>
      <Logo type='dark' />
      <Box sx={{
        display: 'flex',
        gap: 3
      }}>
        {pageLinks.map((item) => (
          <GenericLink key={item?.url} color='secondary' underlineActive={true} {...item} />
        ))}
        <ButtonLink color='secondary' {...contact} />
      </Box>
    </Box>
  )
}

export default DesktopHeader
