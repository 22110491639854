import { cloneElement } from 'react'
import PropTypes from 'prop-types'
// import Slide from '@mui/material/Slide'
import useScrollTrigger from '@mui/material/useScrollTrigger'


const ScrollBehavior = ({ children, mode }) => {

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  })

  if (mode === 'none') {
    return children
  }

  return (
    cloneElement(children, {
      color: trigger ? 'primary' : 'transparent',
      style: {
        transitionProperty: 'all',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
      }
    })
  )
}

ScrollBehavior.propTypes = {
  children: PropTypes.element.isRequired,
  mode: PropTypes.oneOf(['none', 'color']).isRequired,
}

export default ScrollBehavior
