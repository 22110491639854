import React from 'react'
import PropTypes from 'prop-types'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'gatsby'

const getStyles = (theme, isDark) => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover, &.active': {
    '& #link-label': {
      marginBottom: '-1px',
      borderBottom: `1px solid ${isDark ? theme.palette.common.white : theme.palette.primary.main}`
    }
  }
})

const StyledLightLink = styled(Link)(({ theme }) => getStyles(theme))
const StyledLightA = styled('a')(({ theme }) => getStyles(theme))

const StyledDarkLink = styled(Link)(({ theme }) => getStyles(theme, true))
const StyledDarkA = styled('a')(({ theme }) => getStyles(theme, true))

const GenericLink = ({ color, label, url, icon, openNewTab, onClick, underlineActive }) => {
  const isDarkMode = color === 'secondary'
  const LinkComponent = isDarkMode
    ? openNewTab ? StyledDarkA : StyledDarkLink
    : openNewTab ? StyledLightA : StyledLightLink

  return (
    <LinkComponent
      activeClassName={underlineActive ? 'active' : ''}
      onClick={onClick}
      {...(openNewTab ? { target: '_blank', href: url } : { to: url })}
    >
      <Typography
        variant='body2'
        id='link-label'
        sx={{
          textTransform: 'uppercase',
          color: isDarkMode ? 'common.white' : 'text.primary'
        }}
      >
        {label}
      </Typography>
      {icon && (
        <ArrowOutwardIcon
          fontSize='medium'
          sx={{
            color: isDarkMode ? 'common.white' : 'text.primary',
            ml: 1.5,
            marginTop: '-1px',  // alignment tweak
          }}
        />)
      }
    </LinkComponent>
  )
}

GenericLink.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.bool,
  openNewTab: PropTypes.bool,
  onClick: PropTypes.func,
  underlineActive: PropTypes.bool,
}

GenericLink.defaultProps = {
  underlineActive: false,
}

export default GenericLink
