import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Link } from 'gatsby'

const ButtonLink = ({ color, label, url, onClick, fullWidth }) => {
  const isDarkMode = color === 'secondary'
  return (
    <Link
      to={url}
      onClick={onClick}
      style={{
        textDecoration: 'none',
        display: 'inline-flex',
        cursor: 'pointer',
        ...(fullWidth? {width: '100%'} : {}),
      }}
    >
      <Typography
        variant='body2'
        sx={{
          textTransform: 'uppercase',
          textAlign: 'center',
          color: isDarkMode ? 'text.primary' : 'common.white',
          px: 2,
          py: 1.5,
          border: 'solid 1px',
          borderColor: isDarkMode ? 'common.white' : 'secondary.main',
          backgroundColor: isDarkMode ? 'common.white' : 'secondary.main',
          '&:hover': {
            backgroundColor: isDarkMode ? 'primary.main' : 'common.white',
            color: isDarkMode ? 'common.white' : 'secondary.main',
          },
          transitionDuration: '250ms, 250ms',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)',
          transitionDelay: '0ms, 0ms',
          transitionProperty: 'background-color, color',
          ...(fullWidth? {width: '100%'} : {}),
        }}
      >
        {label}
      </Typography>
    </Link>
  )
}

ButtonLink.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
}

export default ButtonLink
