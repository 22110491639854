import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { NotificationProvider } from '../../contexts'

const NotificationWrapper = ({ duration, children }) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState({
    message: '',
    secondaryMessage: '',
    error: false
  })

  const notify = (error, message, secondaryMessage) => {
    setState({ error, message, secondaryMessage })
    setShow(true)
  }

  const closeNotification = () => setShow(false)

  return (
    <NotificationProvider value={{
      show,
      state,
      duration,
      notify,
      closeNotification
    }}>
      {children}
    </NotificationProvider>
  )
}

NotificationWrapper.propTypes = {
  duration: PropTypes.number,
  children: PropTypes.node.isRequired
}

export default NotificationWrapper