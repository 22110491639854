const palette = {
    common: {
        black: '#000',
        white: '#fff',
    },
    primary: {
        main: '#14171F' // deep cove
    },
    secondary: {
        main: '#3363FF', // bluebell
        light: '#668CFF' // azure
    },
    error: {
        main: '#DF4949', // tulip
    },
    warning: {
        main: '#FFD964', // energy
    },
    success: {
        main: '#008F70', // moss
    },
    text: {
        primary: '#010005', // charcoal
    },
    background: {
        default: '#FFFFFF' // white
    },
    grey: {
        50: '#F7F7F7', // alabaster
        100: '#EFEEF1', // gallery
        200: '#E4E3E8', // quartz
        300: '#ADABBA', // granite
        400: '#7D7C83' // petrol
    },
    accent: {
        50: '#B7C6FA', // fog
    }
}

export default palette
