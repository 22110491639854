import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, IconButton } from '@mui/material'

import Logo from '../Logo'

const MobileHeader = ({ mobileMenuExpanded, toggleDrawer }) => {
  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Logo type='dark' onClick={toggleDrawer(false)} />
      <Box sx={{
        display: 'inline-flex',
        flex: 1,
        justifyContent: 'flex-end',
        color: 'white',
      }}>
        <IconButton
          edge='end'
          color='inherit'
          aria-label='menu'
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.04)',
            },
          }}
          onClick={toggleDrawer(!mobileMenuExpanded)}
        >
          {mobileMenuExpanded ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Box>
    </Box>
  )
}

MobileHeader.propTypes = {
  mobileMenuExpanded: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
}

export default MobileHeader
