function OutlinedInput(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          px: 1.5,
          py: 1,
          width: '100%',
          borderRadius: 0,
          border: `1px solid ${theme.palette.grey[300]}`,
          '&:hover': {
            borderColor: theme.palette.text.primary,
            outline: 0
          },
          '&:focus': {
            borderColor: theme.palette.text.primary,
            outline: 0
          },
          '&:focus-visible': {
            borderColor: theme.palette.text.primary,
            outline: 0
          },
          '&:focus-within': {
            borderColor: theme.palette.text.primary,
            outline: 0
          }
        },
        input: {
          ...theme.typography.subtitle2,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          maxHeight: 48,
          outline: 0,
          '&::placeholder': {
            color: theme.palette.grey[400],
            opacity: 1
          }
        },
        disabled: {
          opacity: 0.6,
          '&:hover': {
            outline: 0
          }
        },
        focused: {
          borderColor: theme.palette.text.primary,
          outline: 0
        },
        notchedOutline: {
          border: 0
        },
        error: {
          borderColor: theme.palette.error.main,
          '& input': {
            color: theme.palette.error.main,
          },
          '&:hover': {
            borderColor: theme.palette.error.main,
            outline: 0
          },
          '&:focus': {
            borderColor: theme.palette.error.main,
            outline: 0
          },
          '&:focus-visible': {
            borderColor: theme.palette.error.main,
            outline: 0
          },
          '&:focus-within': {
            borderColor: theme.palette.error.main,
            outline: 0
          }
        }
      }
    }
  }
}

export default OutlinedInput