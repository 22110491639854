function Tab(theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle1,
          textTransform: 'capitalize',
          padding: theme.spacing(1, 1.5),
          maxHeight: 48,
          marginRight: theme.spacing(2),
          backgroundColor: theme.palette.grey[100],
          '&.MuiButtonBase-root': {
            borderColor: theme.palette.grey[200],
            borderWidth: '1px',
            borderStyle: 'solid',
          },
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main
            },
            '&.MuiButtonBase-root': {
              borderColor: theme.palette.primary.main,
            },
          },
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.grey[200]
          }
        }
      }
    }
  }
}

export default Tab