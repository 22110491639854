function typography(theme, palette) {
    return {
        h1: { // H1
            fontFamily: 'Raleway',
            fontSize: 80,
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '88px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        h2: { // H2
            fontFamily: 'Raleway',
            fontSize: 48,
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '56px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        h3: { // H3
            fontFamily: 'Raleway',
            fontSize: 40,
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: '56px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        h4: { // H4
            fontFamily: 'Raleway',
            fontSize: 32,
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: '40px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        h5: { // H5
            fontFamily: 'Roboto',
            fontSize: 24,
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: '32px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        h6: { // H6
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: '32px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        subtitle1: { // Body 500
            fontFamily: 'Roboto',
            fontSize: 18,
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: '32px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        subtitle2: { // Body 400
            fontFamily: 'Roboto',
            fontSize: 18,
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '32px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        body1: { // Small
            fontFamily: 'Roboto Mono',
            fontSize: 16,
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '24px',
            letterSpacing: 0,
            color: palette.text.primary
        },
        body2: { // Label
            fontFamily: 'Roboto',
            fontSize: 16,
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '24px',
            letterSpacing: '0.2em',
            color: palette.text.primary
        },
    }
}

export default typography
