import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Container } from '@mui/material'

import ScrollBehavior from './ScrollBehavior'
import MobileDropdownMenu from './header/MobileDropdownMenu'
import MobileHeader from './header/MobileHeader'
import DesktopHeader from './header/DesktopHeader'

const Header = ({ landingPage }) => {

  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false)
  const scrollToColor = landingPage && !mobileMenuExpanded
  const initialColor = scrollToColor ? 'transparent' : 'primary'

  const toggleDrawer = (open) => (event) => {
    if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
      return
    }
    setMobileMenuExpanded(open)
  }
  const height = 80

  // console.log('landingPage', landingPage)
  // console.log('scrollToColor', scrollToColor)
  // console.log('mobileMenuExpanded', mobileMenuExpanded)

  return (
    <ScrollBehavior mode={scrollToColor ? 'color' : 'none'} >
      <AppBar color={initialColor} sx={{ boxShadow: 0 }}>
        <Container sx={{
          height,
          display: {xs: 'none', sm: 'flex'},
          alignItems: 'center',
        }}>
          <DesktopHeader />
        </Container>
        <Container sx={{
          height,
          display: {xs: 'flex', sm: 'none'},
          alignItems: 'center',
        }}>
          <MobileHeader
            mobileMenuExpanded={mobileMenuExpanded}
            setMobileMenuExpanded={setMobileMenuExpanded}
            toggleDrawer={toggleDrawer}
          />
          <MobileDropdownMenu
            open={mobileMenuExpanded}
            onClose={toggleDrawer(false)}
          />
        </Container>
      </AppBar>
    </ScrollBehavior>
  )
}

Header.propTypes = {
  landingPage: PropTypes.bool,
}

Header.defaultProps = {
  landingPage: false,
}

export default Header
