import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { LargeLogo } from '../../constants'

const Logo = ({ height, type, width, Icon, onClick }) => {
  const Svg = Icon || { light: LargeLogo, dark: LargeLogo }[type]
  return (
    <Link to='/' onClick={onClick} sx={{
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Svg height={height} width={width} />
    </Link>
  )
}

Logo.propTypes = {
  height: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  Icon: PropTypes.elementType,
  onClick: PropTypes.func,
}

Logo.defaultProps = {
  type: 'light',
  Icon: null,
  onClick: null
}

export default Logo
