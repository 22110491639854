import { createTheme } from '@mui/material/styles'

import palette from './palette'
import typography from './typography'
import components from './components'
import shadows from './shadows'

const options = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1200,
        lg: 1600,
        xl: 2400,
      },
    },
    palette,
    typography: typography(createTheme(), palette)
}

const theme = createTheme(options)
theme.components = components(theme)
shadows(theme).forEach((shadow) => theme.shadows.push(shadow))

export default theme
